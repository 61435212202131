import styles from '../App.module.scss';
import DataSender from './DataSender';

function Contact() {
  return (
    <div>
      <div>
        Contact
      </div>
      <div>
        <DataSender />
      </div>
    </div>
  );
}


export default Contact;