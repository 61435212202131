import React, { useState } from 'react';

function DataSender() {
  // State for holding input values
  const [firstName, setFirstName] = useState('Trenton');
  const [lastName, setLastName] = useState('South');
  
  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('ajax.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `action=addUser&FirstName=${encodeURIComponent(firstName)}&LastName=${encodeURIComponent(lastName)}`,
      });

      if (response.ok) {
        const responseBody = await response.text();
        console.log('Server response:', responseBody);
        // Handle success (e.g., show a message to the user)
      } else {
        console.error('Server responded with a status:', response.status);
        // Handle error
      }
    } catch (error) {
      console.error('Request failed:', error);
      // Handle network error
    }
  };

  //Create a function to switch the first and last name
  const switchNames = () => {
    setFirstName(lastName);
    setLastName(firstName);
  }

  return (
    <div>
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        placeholder="First Name"
      />
      <input
        type="text"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        placeholder="Last Name"
      />
      <button type="submit">Send Data</button>
    </form>
    <button onClick={switchNames} type="button">Switch</button>
    </div>
  );
}

export default DataSender;
