import styles from '../App.module.scss';


function Jokes() {
  return (
    <div>
<span class="trenton">Joke page</span>
    </div>
  );
}

export default Jokes;