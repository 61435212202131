import React, { useState } from 'react';
import riddleStyles from './Riddles.module.scss';

function Riddles() {

    const [showTrentonClass, setShowTrentonClass] = useState(true);

    const toggleTrentonClass = () => {
      setShowTrentonClass(!showTrentonClass);
    };
  
    // Use the imported styles object to access the class
    const className = showTrentonClass ? riddleStyles.trenton : '';

  return (
    <div>
<span className={showTrentonClass ? riddleStyles.trenton : ''}>Trenton</span>
<div>
      <button onClick={toggleTrentonClass}>Toggle Trenton Style</button>
    </div>
    </div>
  );
}

export default Riddles;