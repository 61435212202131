import styles from '../App.module.scss';

function About() {
  return (
    <div>
About
    </div>
  );
}

export default About;