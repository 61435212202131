import styles from '../App.module.scss';

function Home() {
  return (
    <div>
<img src="ram.png" alt="Riddles And More Logo" class="logo"></img>
    </div>
  );
}

export default Home;